import * as React from 'react'
import { Container, Row, Col, Card } from 'react-bootstrap'
import Layout from '../../components/layout'
import { Titulo } from '../../components/titulo'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCalendarAlt } from '@fortawesome/free-regular-svg-icons'
import h1b from '../../docs/horario-de-clases/HorarioDeClases-1.pdf'
import h2b from '../../docs/horario-de-clases/HorarioDeClases-2.pdf'
import h3b from '../../docs/horario-de-clases/HorarioDeClases-3.pdf'
import h4b from '../../docs/horario-de-clases/HorarioDeClases-4.pdf'
import h5b from '../../docs/horario-de-clases/HorarioDeClases-5.pdf'
import h6b from '../../docs/horario-de-clases/HorarioDeClases-6.pdf'
import h7b from '../../docs/horario-de-clases/HorarioDeClases-7.pdf'
import h8b from '../../docs/horario-de-clases/HorarioDeClases-8.pdf'

export default function horario () {
    const horarios = [
        {id:'1', name:'1° Básico', horario:h1b, icon:faCalendarAlt},
        {id:'2', name:'2° Básico', horario:h2b, icon:faCalendarAlt},
        {id:'3', name:'3° Básico', horario:h3b, icon:faCalendarAlt},
        {id:'4', name:'4° Básico', horario:h4b, icon:faCalendarAlt},
        {id:'5', name:'5° Básico', horario:h5b, icon:faCalendarAlt},
        {id:'6', name:'6° Básico', horario:h6b, icon:faCalendarAlt},
        {id:'7', name:'7° Básico', horario:h7b, icon:faCalendarAlt},
        {id:'8', name:'8° Básico', horario:h8b, icon:faCalendarAlt},
    ]

    return (
        <Layout>
            <Titulo title="Horario de clases online 2021"  />
            <Container>
                <Row>
                    {horarios && horarios.map( e => (
                        <Col xs={12} md={4} lg={3} key={e.id}>
                            <Card className="bg-dark mt-4">
                                <Card.Body className="text-center">
                                    <h4>{e.name}</h4>
                                    <a href={ e.horario } target="_blank" rel="noreferrer" className="btn btn-primary btn-outlined btn-block">
                                        <span><FontAwesomeIcon icon={e.icon} /> Ver Horario</span>
                                    </a>
                                </Card.Body>
                            </Card>
                        </Col>
                    ))}
                    <Col md={12} className="mb-5"></Col>
                </Row>
            </Container>
        </Layout>
    )
}